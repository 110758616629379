import style from './CardMyWork.module.css'
import { Card, Image } from 'antd';
const { Meta } = Card;
export const CardMyWork = ({el}) => {

  return(
          <Card
              className={style.cardMyWork}
              style={{
                  width: 300,
              }}
          >
                  <Image
                      width={250}
                      src={el.img}
                  />
              <Meta title={<div className={style.title}>{el.title}</div>} description={<div style={{color:"black"}}>{el.description} <a  href={el.http}>Перейти</a></div>}  />
          </Card>
     )

}