import { Menu } from 'antd';
import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import style from './NavigateTop.module.css'
export const NavigateTop = () => {

    const items=[
        {
            label: 'About Me',
            key: 'about',
            icon: <MailOutlined />,
        },{
            label: 'Contact Me',
            key: 'contact',
            icon: <MailOutlined />,
        },
    ]
    const [current, setCurrent] = useState('about');
    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };
  return (
      <div className={style.menu}>
          <Menu  style={{background:'none', color:'red'}} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
      </div>

  )
}