import {CardMyWork} from './components/CardMyWork'
import { Avatar, Space } from 'antd';
import './App.css';

import {NavigateTop} from "./components/NavigateTop";

function App() {
    const myWorks=[{
      title:'Patients Accounting',
      http:'https://patients-accounting.alexwebco.com/',
        img:'/imagesSite/img1.png',
        description:'Приложение по управлению базой данных приемов пациентов.'
    }, {
        title:'Балтийский мёд',
        http:'https://baltiyskiy-med.ru/Main/Main',
        img:'/imagesSite/img2.png',
        description:'Сайт по пчелам и продуктам пчеловодства.'
    }, ]
  return (
    <div className="App">
        <div className='appWrapper'>
            <div className='wrapperTop'>
                <Avatar className='avatar' size={200} src={'/imagesSite/avatar.png'}/>
                <NavigateTop/>
                <h1>Alex WebCo</h1>

            </div>

            <div className='myWorkContainer'>
                {
                    myWorks.map((el, i)=><CardMyWork key={i} el={el}/>)
                }
            </div>
        </div>

    </div>
  );
}

export default App;
